.site__container {
  .store_profile {
    .date_picker {
      .form-group {
        flex-direction: row;
        margin-bottom: 20px;
        .prefixes {
          width: 120px;
          font-size: 14px;
          font-weight: 700;
          text-align: right;
          float: left;
          margin: 18px 25px 0 0;
        }
        .suffixes {
          font-size: 14px;
          font-weight: 700;
          text-align: left;
          float: left;
          margin: 18px 0 0 25px;
        }
        &.child_counter .MuiTextField-root {
          width: 150px;
        }
      }
      .react-calendar {
        .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
        .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
          display: none;
        }
      }
    }
    .picked_date {
      color: $secondaryColor;
      font-size: 18px;
      font-weight: 800;
      margin-top: 10px;
      text-transform: uppercase;
      span {
        font-size: 42px;
        line-height: 0.8;
        display: block;
        margin-bottom: 4px;
      }
    }
    .session_title {
      color: $secondaryColor;
      font-size: 12px;
      font-weight: 700;
      margin: 5px 0 25px;
      letter-spacing: 0.75px;
      text-transform: uppercase;
    }
    .slot_label {
      text-align: center;
      width: 100%;
      display: block;
      padding: 12px 0;
      margin-left: 0;
      border-radius: 5px;
      border: 1px solid #ccc;
      .MuiCheckbox-colorPrimary {
        display: none;
      }
    }
    .loading_wrap {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .slot_wrap {
    padding-left: 22px;
    display: inline-block;
    p {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: -24px;
    }
    .slot_block {
      margin: 0;
      padding: 15px 0 0;
    }
  }
  .slot_grid {
    flex-basis: 50%;
    float: left;
    padding-top: 5px !important;
    .slot_block {
      display: block;
      margin: 7px auto 0;
      position: relative;
      .MuiCheckbox-root {
        top: -2px;
        left: -25px;
        padding: 0;
        position: absolute;
        svg.MuiSvgIcon-root {
          width: 19px;
        }
      }
      .ft_block {
        width: 13px;
        height: 13px;
        margin: 0 3px;
        display: inline-block;
        background: $secondaryColor;
        &.booked {
          background-color: #a5a5a5;
        }
        &:nth-child(1) {
          margin-left: 1px;
        }
      }
    }
  }
  .col_title {
    margin-bottom: 42px;
    border-bottom: 3px solid;
    h4 {
      color: $primaryColor;
      font-size: 28px;
      font-weight: 900;
      line-height: 1.3;
      text-transform: uppercase;
    }
  }
  .time_col {
    border-top: 4px solid $primaryColor;
  }
  .button-row {
    margin-top: 35px;
  }
  .key {
    text-align: right;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
    padding-top: 12px;
    border-top: 1px solid #e2e2e2;
    li {
      color: #333;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      div {
        width: 15px;
        height: 15px;
        float: right;
        margin: 2px 0px 0 8px;
      }
    }
  }
}