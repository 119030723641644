@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;600;800&display=swap");
body {
  font-family: "Poppins", sans-serif;
}

.site__container {
  min-height: 100vh;
  background: #f1f5f9;
}

.adm_theme-btn {
  min-width: 200px !important;
  color: #fff !important;
  background: #040033 !important;
}

.adm-page-title-input {
  float: right;
}

.MuiTouchRipple-root {
  border-radius: 0 !important;
}

.store__dashboard .account_menu .MuiAvatar-root {
  margin-right: 5px;
}
.store__dashboard .account_menu .account-menu-btn {
  margin-top: 5px;
}
.store__dashboard .account_menu .account-menu-btn p {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.store__dashboard .account_menu .account-menu-btn p span {
  font-size: 12px;
  font-weight: 300;
  display: block;
  margin: 0px 0 -5px 0;
}
.store__dashboard .account_menu .account-menu-btn:hover {
  background: transparent;
}

.site__container .theme-btn {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  padding: 8px 12px;
  display: inline-block;
  text-transform: uppercase;
  min-width: 120px;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 100px;
  text-decoration: none !important;
  background: #0d6de5;
  border: 1px solid #0d6de5;
}
.site__container .theme-btn.secondary-btn {
  background-color: #6f7072;
  border: 1px solid #6f7072;
}
.site__container .theme-btn.secondary-btn:hover {
  color: #fff;
  border: 1px solid #6f7072;
}
.site__container .theme-btn:hover {
  color: #0d6de5;
  border: 1px solid #0d6de5;
}

.site__container .store-card {
  color: #333;
  text-decoration: none;
}
.site__container .store-card .store-name {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.site__container .store-card .store-address {
  font-size: 12px;
  font-weight: 500;
  padding-right: 20px;
}
.site__container .store-card .store-contact {
  font-size: 12px;
  font-weight: 500;
  margin: 8px 0 0;
}
.site__container .store-card:hover {
  color: #0d6de5;
}

.day-label {
  color: #858585;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.slotBlock {
  margin-bottom: 8px;
}
.slotBlock .MuiTextField-root {
  width: calc(100% - 32px);
}
.slotBlock .removeBtn {
  padding: 0;
  border: 0;
  background: transparent;
}
.slotBlock .removeBtn svg {
  color: red;
  margin: 7px 0 0;
}

.adm-page-title {
  color: #4b4b4b;
  font-size: 18px !important;
  font-weight: 600 !important;
  display: block;
  margin: 0 0 25px !important;
}

.menu--tab .tab-title {
  color: #4b4b4b;
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 15px;
}
.menu--tab .menu-tab {
  list-style: none;
  padding: 0;
  margin: 0 0 25px;
  border-bottom: 1px solid #ebebeb;
}
.menu--tab .menu-tab li {
  display: inline-block;
}
.menu--tab .menu-tab li a {
  color: #4b4b4b;
  font-size: 13px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: 10px 30px 12px;
}
.menu--tab .menu-tab li:nth-child(1) a {
  padding-left: 0;
}

.store__dashboard tbody tr td {
  padding: 5px 16px !important;
}
.store__dashboard tbody tr td .status_sign {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 100px;
  background: #ccc;
}
.store__dashboard tbody tr td .status_sign.completed {
  background: #00ba07;
}
.store__dashboard tbody tr td .status_sign.rejected {
  background: #f00;
}
.store__dashboard .MuiInputBase-formControl {
  margin-bottom: 20px;
}
.store__dashboard .slotBlock .MuiInputBase-formControl {
  margin-bottom: 2px;
}

.datePicker .MuiOutlinedInput-input {
  height: 0.4375em !important;
}

.booking-modal .MuiInputBase-formControl {
  margin-bottom: 20px;
}
.booking-modal .status_block {
  padding: 8px 10px;
  border-radius: 4px;
  margin: 0 0 10px !important;
  background: #efefef;
  border: 1px solid #c5c5c5;
}
.booking-modal .status_block p {
  font-size: 14px;
}
.booking-modal .date {
  font-size: 12px !important;
  margin: 2px 0 0;
}
.booking-modal .bookingpop-summery {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
}
.booking-modal .bookingpop-summery li p {
  width: 142px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 2px;
}

/* Super Admin */
.sa_theme-btn {
  min-width: 100px;
  font-size: 12px;
  padding: 4px 5px;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
  border-radius: 2px;
}

.welcome_wrap {
  padding: 5vh 0;
}
.welcome_wrap .map_warp {
  display: flex;
  float: right;
  align-items: center;
}
.welcome_wrap .map_warp img {
  max-width: 100%;
}

.login_page .login_container {
  min-height: 95vh;
  align-items: center;
  z-index: 5;
  position: relative;
  font-family: Poppins;
}
.login_page .login_container .form-group {
  font-family: Poppins;
}
.login_page .login_container .form-group .MuiSwitch-root {
  margin: -7px 0 10px -8px;
}
.login_page .form-wrap {
  padding: 32px 42px;
  border-radius: 18px;
  background: #f1f4f9;
}
.login_page::after {
  content: "";
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  position: absolute;
  background: #040033;
}
.login_page h2 {
  color: #0d6de5;
  font-size: 22px !important;
  font-weight: 600 !important;
  margin: 0 0 35px !important;
}
.login_page .body-intro {
  color: #b6b6b6;
  margin-bottom: 55px;
}
.login_page .brand-logo {
  height: 38px;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.login_page .MuiOutlinedInput-root {
  margin-bottom: 20px;
}
.login_page .button-link {
  color: #333;
  font-size: 12px;
  display: inline-block;
  text-transform: uppercase;
}

.site__container .store_profile .date_picker .form-group {
  flex-direction: row;
  margin-bottom: 20px;
}
.site__container .store_profile .date_picker .form-group .prefixes {
  width: 120px;
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  float: left;
  margin: 18px 25px 0 0;
}
.site__container .store_profile .date_picker .form-group .suffixes {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  float: left;
  margin: 18px 0 0 25px;
}
.site__container .store_profile .date_picker .form-group.child_counter .MuiTextField-root {
  width: 150px;
}
.site__container .store_profile .date_picker .react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.site__container .store_profile .date_picker .react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}
.site__container .store_profile .picked_date {
  color: #0d6de5;
  font-size: 18px;
  font-weight: 800;
  margin-top: 10px;
  text-transform: uppercase;
}
.site__container .store_profile .picked_date span {
  font-size: 42px;
  line-height: 0.8;
  display: block;
  margin-bottom: 4px;
}
.site__container .store_profile .session_title {
  color: #0d6de5;
  font-size: 12px;
  font-weight: 700;
  margin: 5px 0 25px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}
.site__container .store_profile .slot_label {
  text-align: center;
  width: 100%;
  display: block;
  padding: 12px 0;
  margin-left: 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.site__container .store_profile .slot_label .MuiCheckbox-colorPrimary {
  display: none;
}
.site__container .store_profile .loading_wrap {
  height: 100%;
  display: flex;
  align-items: center;
}
.site__container .slot_wrap {
  padding-left: 22px;
  display: inline-block;
}
.site__container .slot_wrap p {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: -24px;
}
.site__container .slot_wrap .slot_block {
  margin: 0;
  padding: 15px 0 0;
}
.site__container .slot_grid {
  flex-basis: 50%;
  float: left;
  padding-top: 5px !important;
}
.site__container .slot_grid .slot_block {
  display: block;
  margin: 7px auto 0;
  position: relative;
}
.site__container .slot_grid .slot_block .MuiCheckbox-root {
  top: -2px;
  left: -25px;
  padding: 0;
  position: absolute;
}
.site__container .slot_grid .slot_block .MuiCheckbox-root svg.MuiSvgIcon-root {
  width: 19px;
}
.site__container .slot_grid .slot_block .ft_block {
  width: 13px;
  height: 13px;
  margin: 0 3px;
  display: inline-block;
  background: #0d6de5;
}
.site__container .slot_grid .slot_block .ft_block.booked {
  background-color: #a5a5a5;
}
.site__container .slot_grid .slot_block .ft_block:nth-child(1) {
  margin-left: 1px;
}
.site__container .col_title {
  margin-bottom: 42px;
  border-bottom: 3px solid;
}
.site__container .col_title h4 {
  color: #040033;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
  text-transform: uppercase;
}
.site__container .time_col {
  border-top: 4px solid #040033;
}
.site__container .button-row {
  margin-top: 35px;
}
.site__container .key {
  text-align: right;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  padding-top: 12px;
  border-top: 1px solid #e2e2e2;
}
.site__container .key li {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
.site__container .key li div {
  width: 15px;
  height: 15px;
  float: right;
  margin: 2px 0px 0 8px;
}

.site__container .submission_page {
  margin-top: 52px;
}
.site__container .submission_page .col_title {
  margin-bottom: 0;
}
.site__container .submission_page .summery_block {
  color: #fff;
  padding: 24px 15px 15px;
  border-radius: 0 0 15px 15px;
  background: #0d6de5;
}
.site__container .submission_page .summery_block svg {
  color: #fff;
}
.site__container .submission_page .summery_block .selected_store {
  font-weight: 600;
  letter-spacing: 0.5px;
  padding-left: 30px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  text-transform: capitalize;
  border-bottom: 1px solid #fff;
}
.site__container .submission_page .summery_block .selected_store svg {
  float: none !important;
  display: inline-block;
  margin: 0px 14px -3px 0px !important;
}
.site__container .submission_page .summery_block ul {
  list-style: none;
  padding-left: 30px;
  margin: 0;
}
.site__container .submission_page .summery_block ul li {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.site__container .submission_page .MuiInputBase-formControl {
  margin-bottom: 22px;
}

.thank_you-message {
  width: 50%;
  text-align: center;
  display: block;
  margin: 8% auto 0;
}
.thank_you-message .msg-box .thnk-title {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 32px;
  padding-bottom: 15px;
  border-bottom: 3px solid #040033;
}
.thank_you-message .msg-box .appoint_no {
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  display: inline-block;
  padding: 0px 38px;
  margin: 15px 0 20px;
  border-radius: 100px;
  background: #0d6de5;
}/*# sourceMappingURL=styles.css.map */