.welcome_wrap {
  padding: 5vh 0;
  .map_warp {
    display: flex;
    float: right;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
}