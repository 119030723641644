.store__dashboard {
  .account_menu {
    .MuiAvatar-root {
      margin-right: 5px;
    }
    .account-menu-btn{
      margin-top: 5px;
      p {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        span {
          font-size: 12px;
          font-weight: 300;
          display: block;
          margin: 0px 0 -5px 0;
        }
      }
      &:hover {
        background: transparent;
      }
    }
  }
}