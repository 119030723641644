.site__container {
  .theme-btn {
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    padding: 8px 12px;
    display: inline-block;
    text-transform: uppercase;
    min-width: 120px;
    text-align: center;
    letter-spacing: 2px;
    border-radius: 100px;
    text-decoration: none !important;
    background: $secondaryColor;
    border: 1px solid $secondaryColor;
    &.secondary-btn {
      background-color: #6f7072;
      border: 1px solid #6f7072;
      &:hover {
        color: #fff;
        border: 1px solid #6f7072;
      }
    }
    &:hover {
      color: $secondaryColor;
      border: 1px solid $secondaryColor;
    }
  }
}