.site__container {
  .store-card {
    color: #333;
    text-decoration: none;
    .store-name {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    .store-address {
      font-size: 12px;
      font-weight: 500;
      padding-right: 20px;
    }
    .store-contact {
      font-size: 12px;
      font-weight: 500;
      margin: 8px 0 0;
    }
    &:hover {
      color: $secondaryColor;
    }
  }
}