.day-label {
  color: #858585;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.slotBlock {
  margin-bottom: 8px;
  .MuiTextField-root {
    width: calc(100% - 32px);
  }
  .removeBtn {
    padding: 0;
    border: 0;
    background: transparent;
    svg {
      color: red;
      margin: 7px 0 0;
    }
  }
}

// .MuiInputBase-formControl {
//   margin-bottom: 20px;
// }

.adm-page-title {
  color: #4b4b4b;
  font-size: 18px !important;
  font-weight: 600 !important;
  display: block;
  margin: 0 0 25px !important;
}

.menu--tab{
  .tab-title {
    color: #4b4b4b;
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 15px;
  }
  .menu-tab {
    list-style: none;
    padding: 0;
    margin: 0 0 25px;
    border-bottom: 1px solid #ebebeb;
    li {
      display: inline-block;
      a {
        color: #4b4b4b;
        font-size: 13px;
        font-weight: 500;
        display: block;
        text-decoration: none;
        padding: 10px 30px 12px;
      }
      &:nth-child(1) a {
        padding-left: 0;
      }
    }
  }
}  


.store__dashboard {
  tbody tr td {
    padding: 5px 16px !important;
    .status_sign {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-radius: 100px;
      background: #ccc;
      &.completed {
        background: #00ba07;
      }
      &.rejected {
        background: #f00;
      }
    }
  }
  .MuiInputBase-formControl {
    margin-bottom: 20px;
  }
  .slotBlock .MuiInputBase-formControl {
    margin-bottom: 2px;
  }
}
.datePicker .MuiOutlinedInput-input {
  height: 0.4375em !important;
}
.booking-modal {
  .MuiInputBase-formControl {
    margin-bottom: 20px;
  }
  .status_block {
    padding: 8px 10px;
    border-radius: 4px;
    margin: 0 0 10px !important;
    background: #efefef;
    border: 1px solid #c5c5c5;
    p {
      font-size: 14px;
    }
  }
  .date {
    font-size: 12px !important;
    margin: 2px 0 0;
  }
  .bookingpop-summery {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
    li {
      p {
        width: 142px;
        font-size: 13px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 2px;
      }
    }
  }
}

/* Super Admin */
.sa_theme-btn {
  min-width: 100px;
  font-size: 12px;
  padding: 4px 5px;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
  border-radius: 2px;
}

