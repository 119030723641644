.thank_you-message {
  width: 50%;
  text-align: center;
  display: block;
  margin: 8% auto 0;
  .msg-box {
    .thnk-title {
      font-size: 28px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 32px;
      padding-bottom: 15px;
      border-bottom: 3px solid $primaryColor;
    }
    .appoint_no {
      color: #fff;
      font-size: 34px;
      font-weight: 600;
      display: inline-block;
      padding: 0px 38px;
      margin: 15px 0 20px;
      border-radius: 100px;
      background: $secondaryColor;
    }
  }
}