.site__container {
  .submission_page {
    margin-top: 52px;
    .col_title  {
      margin-bottom: 0;
    }
    .summery_block {
      color: #fff;
      padding: 24px 15px 15px;
      border-radius: 0 0 15px 15px;
      background: $secondaryColor;
      svg {
        color: #fff;
      }
      .selected_store  {
        font-weight: 600;
        letter-spacing: 0.5px;
        padding-left: 30px;
        padding-bottom: 5px;
        margin-bottom: 20px;
        text-transform: capitalize;
        border-bottom: 1px solid #fff;
        svg {
          float: none !important;
          display: inline-block;
          margin: 0px 14px -3px 0px !important;
        }
      }
      ul {
        list-style: none;
        padding-left: 30px;
        margin: 0;
        li {
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 1px;
          margin-bottom: 10px;
        }
      }
    }
    .MuiInputBase-formControl {
      margin-bottom: 22px;
    }
  }
}