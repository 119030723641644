.login_page{
  .login_container {
    min-height: 95vh;
    align-items: center;
    z-index: 5;
    position: relative;
    font-family: Poppins;
    .form-group {
      font-family: Poppins;
      .MuiSwitch-root {
        margin: -7px 0 10px -8px;
      }
    }
  }
  .form-wrap {
    padding: 32px 42px;
    border-radius: 18px;
    background: #f1f4f9;
  }
  &::after {
    content: '';
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
    background: $primaryColor;
  }
  h2 {
    color: $secondaryColor;
    font-size: 22px !important;
    font-weight: 600 !important;
    margin: 0 0 35px !important;
  }
  .body-intro {
    color: #b6b6b6;
    margin-bottom: 55px;
  }
  .brand-logo {
    height: 38px;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  .MuiOutlinedInput-root {
    margin-bottom: 20px;
  }
  .button-link {
    color: #333;
    font-size: 12px;
    display: inline-block;
    text-transform: uppercase;
  }
}